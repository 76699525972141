/* eslint no-console: "off" */

import { AzureLogger, setLogLevel } from '@azure/logger';
import bootstrapped from './bootstrapped';

type LogLevel = 'info' | 'warn' | 'error' | 'debug';

// Sentry and R7Insight are included globally
declare const Sentry: any;
declare const R7Insight: any;

export class Log {
    public static info(msg: string, additionalData?: unknown): void {
        Log.emitLogMessage('info', msg, additionalData);
    }

    public static warn(msg: string, additionalData?: unknown): void {
        Log.emitLogMessage('warn', msg, additionalData);
    }

    public static debug(msg: string, additionalData?: unknown): void {
        Log.emitLogMessage('debug', msg, additionalData);
    }

    public static error(
        msg: string,
        additionalData?: unknown,
        error?: Error,
    ): void {
        Log.emitLogMessage('error', msg, additionalData, error);
    }

    private static isR7Initialized = false;

    private static emitLogMessage(
        msgType: LogLevel,
        msg: string,
        additionalData: unknown,
        error?: Error,
    ): void {
        if (console && console[msgType]) {
            if (additionalData != null || error != null) {
                const data = this.mapData({}, additionalData, error);
                console[msgType](msg, data);
            } else {
                console[msgType](msg);
            }
        }

        try {
            Log.logToR7Insight(msgType, msg, additionalData, error);
        } catch (e) {
            console.error('Error logging to R7Insight', e);
        }

        try {
            Log.logToSentry(msgType, msg, additionalData, error);
        } catch (e) {
            console.error('Error logging to Sentry', e);
        }
    }

    private static logToR7Insight(
        msgType: LogLevel,
        msg: string,
        additionalData: unknown,
        error?: Error,
    ): void {
        if (typeof R7Insight === 'undefined') {
            return;
        }
        if (msgType === 'debug') {
            return;
        }
        if (!bootstrapped.LeToken) {
            return;
        }

        if (!Log.isR7Initialized) {
            R7Insight.init({
                token: bootstrapped.LeToken,
                ['page_info']: 'per-page',
                region: 'eu',
            });
            Log.isR7Initialized = true;
        }

        const basicData: { [key: string]: any } = {
            user: bootstrapped.UserId,
            message: msg,
        };

        const data = this.mapData(basicData, additionalData, error);

        // Normalizing log level so we can search the same way across all log channels
        data['level'] = msgType;

        R7Insight[msgType](data);
    }

    private static logToSentry(
        msgType: LogLevel,
        msg: string,
        additionalData: unknown,
        error?: Error,
    ): void {
        if (msgType === 'info' || msgType === 'debug') {
            return;
        }
        if (typeof Sentry === 'undefined') {
            return;
        }

        const basicData: { [key: string]: any } = {
            user: bootstrapped.UserId,
            message: msg,
        };

        const data = this.mapData(basicData, additionalData, error);

        Sentry.setContext('data', data);

        if (additionalData instanceof Error) {
            Sentry.captureException(additionalData, { level: msgType });
        } else if (error instanceof Error) {
            Sentry.captureException(error, { level: msgType });
        } else {
            Sentry.captureMessage(msg, { level: msgType });
        }
    }

    private static mapData(
        basicData: { [key: string]: any },
        additionalData?: unknown,
        error?: Error,
    ): { [key: string]: any } {
        const xData = basicData ? basicData : {};

        if (additionalData && additionalData instanceof Error) {
            xData['error-name'] = additionalData.name;
            xData['error-message'] = additionalData.message;
            xData['error-stack'] = additionalData.stack;
        } else if (additionalData != null) {
            if (
                additionalData instanceof Object &&
                !Array.isArray(additionalData)
            ) {
                Object.keys(additionalData).forEach((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    xData[key] = additionalData[key];
                });
            } else {
                xData['data'] = additionalData;
            }
        }

        if (error && error instanceof Error) {
            xData['error-name'] = error.name;
            xData['error-message'] = error.message;
            xData['error-stack'] = error.stack;
        }

        return xData;
    }
}

// Configuring @azure/logger
setLogLevel('warning');
AzureLogger.log = (message) => {
    const level = getLogLevel(message);

    switch (level) {
        case 'info':
            Log.info(message);
            break;
        case 'warn':
            Log.warn(message);
            break;
        case 'error':
            Log.error(message);
            break;
        case 'debug':
            Log.debug(message);
            break;
    }
};

const downgradedErrorLogs = [
    'Disconnecting Call end reason=Success',
    'resultCategories=Success',
    'Failed to set media metrics device events telemetry',
    ':SenderV2 Not setting up audio mixer for non-audio stream',
    'ACS-calling:.*\\(Failed to dispose calling stack',
    'Unable to receive incoming calls',
    'CallClient1:CallStack:Trouter \\[Connection\\] onSocketDisconnect, reason: disconnect',
    'CallClient1:CallStack:MA:DeviceManager Error updating renderers:.*audio requested undefined, video requested: true',
    'CallClient1:CallStack:MA:DeviceManager:videorenderer:local:.*:rendererQueue.*Error from promiseQueue.*type.*noDeviceSelected.*detail.*audio requested undefined.*video requested.*',
];

const downgradedErrorLogsRegex = new RegExp(downgradedErrorLogs.join('|'));

function getLogLevel(message: string): LogLevel {
    if (message.includes(':error')) {
        if (downgradedErrorLogsRegex.test(message)) {
            return 'info';
        }

        return 'error';
    } else if (message.includes(':warning')) {
        return 'warn';
    } else if (message.includes(':info')) {
        return 'info';
    } else {
        // :verbose
        return 'debug';
    }
}
