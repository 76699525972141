export { format } from "@hejdoktor/resx-compiler"
import { getLocale } from "@hejdoktor/resx-compiler"

import { R as _da } from "./Resources.da.resx"
import { R as _en } from "./Resources.en.resx"

type Translations = {
	"Name1": string,
	"Bitmap1": string,
	"Icon1": string,
	"BookTimeVideoCall": string,
	"BookVideoConsultation_FirstAvailableCaregiver": string,
	"CaregiverChat_ConnectionLost_Message": string,
	"CaregiverChat_MessageList_NewMessagePlaceholder": string,
	"CaregiverChat_MessageList_SendButton": string,
	"CaregiverChat_MessageList_Title": string,
	"CaregiverChat_OnDutyList_NoCaregiversLabel": string,
	"CaregiverChat_OnDutyList_Title": string,
	"CprRequired": string,
	"Close": string,
	"FieldRequired": string,
	"EmailRequired": string,
	"PhoneNumberRequired": string,
	"ErrorFetchingTimetable": string,
	"ErrorOccured": string,
	"ErrorRate": string,
	"ErrorSchedulingConsultation": string,
	"ErrorSendingMessage": string,
	"InvalidCpr": string,
	"CprTooShort": string,
	"CprTooOld": string,
	"CprTooYoung": string,
	"InvalidName": string,
	"InvalidEmail": string,
	"PleaseChooseDate": string,
	"RequirePaymentText": string,
	"SubjectRequired": string,
	"ThankYouForRating": string,
	"ErrorCreateConsultation": string,
	"PartnerEmailRequired": string,
	"PartnerNameRequired": string,
	"PartnerCprRequired": string,
	"Active": string,
	"Invited": string,
	"NoPartner": string,
	"AlreadyHasPartner": string,
	"EmailAlreadyExist": string,
	"ErrorCompanyData": string,
	"CompanyNameRequired": string,
	"ErrorCompany": string,
	"DeleteCompanyDescription": string,
	"DeleteCompanyTitle": string,
	"ErrorDeletingCompany": string,
	"DeleteCompanyWhiteListDescription": string,
	"DeleteCompanyWhiteListTitle": string,
	"DomainRequired": string,
	"FormInvalidValue": string,
	"ValidDomain": string,
	"ErrorDeletingDoctor": string,
	"ErrorGettingDoctors": string,
	"DoctorEmailAlreadyExist": string,
	"ErrorNewDoctor": string,
	"CouponCodeRequired": string,
	"UsernameRequired": string,
	"FullNameRequired": string,
	"ErrorDeletePartner": string,
	"ErrorUpdateProfileData": string,
	"ErrorValidatingInsuranceMembership": string,
	"InvalidPhoneNumber": string,
	"PhoneCallViewModel_ViewIsClosing": string,
	"ErrorGettingPrices": string,
	"ErrorUpdatePrice": string,
	"InvalidPayment": string,
	"TimeSlotUnavailable": string,
	"Child": string,
	"ErrorCompanyConsultations": string,
	"Partner": string,
	"ErrorPatients": string,
	"NoDoctorsAvailableForQueueScheduleMessage": string,
	"ValidateCprInvalidCpr": string,
	"WaitingTimeText_Other": string,
	"WaitingTimeText_Singular": string,
	"Friday": string,
	"Monday": string,
	"Saturday": string,
	"Sunday": string,
	"Thursday": string,
	"Tuesday": string,
	"Wednesday": string,
	"PayButton": string,
	"SendButton": string,
	"Today": string,
	"ErrorDeleteAccount": string,
	"ErrorInvitePartner": string,
	"ErrorCallQualityFeedback": string,
	"PleaseEnterFullName": string,
	"CouldNotUpdateChild": string,
	"CouldNotCreateChild": string,
	"UploadFailedTooLarge": string,
	"Profile_AddChild": string,
	"Profile_EditChild": string,
	"FillOutMessage": string,
	"Darn": string,
	"SorryForYourConcerns": string,
	"ThankYou": string,
	"ImproveOurselves": string,
	"Great": string,
	"BigHelp": string,
	"dateFnsShortDateFormat": string,
	"dateFnsTimeFormat": string,
	"dateFnsTodayRelativeFormat": string,
	"dateFnsTomorrowRelativeFormat": string,
	"ProfileConfirmEditKid": string,
	"ClockValidatorError": string,
	"DynamicCodeSubject": string,
	"dateFnsLongDateFormat": string,
	"dateFnsLongDateTimeFormat": string,
	"dateFnsShortDateTimeFormat": string,
	"NameRequired": string,
	"AddMore": string,
	"MaxFilesExceeded": string,
	"InvalidFileType": string,
	"TimeZoneWarning": string,
	"Back": string,
	"Next": string,
	"PFAErrorNoAnswer": string,
	"SelectPlaceholder": string,
	"QuestionXOfY": string,
	"ErrorGettingCaregiverTips": string,
	"ErrorNewCaregiverTip": string,
	"Understood": string,
}

const R = new Proxy({}, {
	get(target, name: keyof Translations, receiver) {
		if(process.env.enforcedLocale === "da" || (!process.env.enforcedLocale && getLocale("da") === "da")) {
			return _da[name] ?? name
		}

if(process.env.enforcedLocale === "en" || (!process.env.enforcedLocale && getLocale("da") === "en")) {
			return _en[name] ?? name
		}

		return name
	},
}) as Translations

export { R }