export const R = {
	"Name1": "this is my long string",
	"Bitmap1": "[base64 mime encoded serialized .NET Framework object]",
	"Icon1": "[base64 mime encoded string representing a byte array form of the .NET Framework object]",
	"BookTimeVideoCall": "Har du ikke tid lige nu, så find en ledig tid herunder og bestil din videokonsultation. Vi sender dig en e-mail med yderligere information inden opkaldet.",
	"BookVideoConsultation_FirstAvailableCaregiver": "Først ledige",
	"CaregiverChat_ConnectionLost_Message": "Vent venligst mens vi prøver at genoprette forbindelsen eller prøv at genindlæse siden.",
	"CaregiverChat_MessageList_NewMessagePlaceholder": "Skriv besked",
	"CaregiverChat_MessageList_SendButton": "Send",
	"CaregiverChat_MessageList_Title": "Chat",
	"CaregiverChat_OnDutyList_NoCaregiversLabel": "Der er ingen behandlere på vagt i dag",
	"CaregiverChat_OnDutyList_Title": "Behandlere på vagt i dag",
	"CprRequired": "CPR skal udfyldes",
	"Close": "Luk",
	"FieldRequired": "Venligst udfyld felt",
	"EmailRequired": "E-mail skal udfyldes",
	"PhoneNumberRequired": "Angiv venligst telefonnummer. Så kan vi få fat på dig, hvis teknikken driller.",
	"ErrorFetchingTimetable": "Der opstod en fejl under hentning af tidsplan. Prøv venligst igen, eller kontakt os hvis problemet fortsætter.",
	"ErrorOccured": "Der opstod en fejl. Prøv venligst igen, eller kontakt os hvis problemet fortsætter.",
	"ErrorRate": "Der opstod en fejl. Kunne ikke gemme rating. Prøv venligst igen, eller kontakt os hvis problemet fortsætter.",
	"ErrorSchedulingConsultation": "Der opstod en fejl under planlægning af konsultationen. Prøv venligst igen, eller kontakt os hvis problemet fortsætter.",
	"ErrorSendingMessage": "Der opstod en fejl under afsendelse af besked. Prøv venligst igen, eller kontakt os hvis problemet fortsætter.",
	"InvalidCpr": "Ugyldigt CPR-nummer",
	"CprTooShort": "CPR nummeret er for kort. Det skal være 10 cifre på formen: DDMMYYXXXX",
	"CprTooOld": "Beklager, denne funktionalitet er kun for børn under 18 år. Voksne skal logge ind med deres eget MitID for at bestille en konsultation.",
	"CprTooYoung": "Beklager, denne funktionalitet er kun for voksne over 18 år. Børn skal oprettes under menuen \"Børn under 18 år\" på profilsiden.",
	"InvalidName": "Det ligner ikke et fuldt navn. Prøv venligst igen.",
	"InvalidEmail": "E-mailen er ugyldig.",
	"PleaseChooseDate": "Vælg dato for videokonsultation",
	"RequirePaymentText": "Jeg er opmærksom på at denne konsultation kræver betaling",
	"SubjectRequired": "Emne skal udfyldes",
	"ThankYouForRating": "Tak for din tilbagemelding",
	"ErrorCreateConsultation": "Der opstod en fejl under oprettelse af konsultationen",
	"PartnerEmailRequired": "Familiemedlems e-mail skal udfyldes",
	"PartnerNameRequired": "Familiemedlems navn skal udfyldes",
	"PartnerCprRequired": "Familiemedlems CPR-nummer skal udfyldes",
	"Active": "Aktiv",
	"Invited": "Inviteret",
	"NoPartner": "Ingen partner",
	"AlreadyHasPartner": "Patient har allerede en partner",
	"EmailAlreadyExist": "Emailen eksisterer allerede i systemet",
	"ErrorCompanyData": "Der opstod en fejl under hentning af virksomhedens data",
	"CompanyNameRequired": "Firmanavn kræves",
	"ErrorCompany": "Der opstod en fejl under oprettelse af virksomheden",
	"DeleteCompanyDescription": "Når du sletter virksomheden \"{companyName}\" vil du miste alle dens data.",
	"DeleteCompanyTitle": "Slet virksomhed",
	"ErrorDeletingCompany": "Fejl opstod under sletning virksomheden",
	"DeleteCompanyWhiteListDescription": "Ved at slette virksomhedens whitelist vil du ikke være i stand til at gendanne den",
	"DeleteCompanyWhiteListTitle": "Slet virksomhedens whitelist",
	"DomainRequired": "Domæne skal udfyldes",
	"FormInvalidValue": "Formularen indeholder ugyldige værdier",
	"ValidDomain": "Gyldigt domænenavn bør starte med '@' (f.eks. @yahoo) Eller gyldig email adresse (f.eks. Company@yahoo.com)",
	"ErrorDeletingDoctor": "Der opstod en fejl under sletning af behandleren",
	"ErrorGettingDoctors": "Der opstod en fejl under hentning af behandlere",
	"DoctorEmailAlreadyExist": "En bruger med samme email er allerede oprettet i systemet. Prøv venligst igen med en anden email.",
	"ErrorNewDoctor": "Der opstod en fejl under oprettelse af ny behandler",
	"CouponCodeRequired": "Kuponkode skal udfyldes",
	"UsernameRequired": "Email skal udfyldes",
	"FullNameRequired": "Navn skal udfyldes",
	"ErrorDeletePartner": "Der opstod en fejl under sletning af partner",
	"ErrorUpdateProfileData": "Der opstod en fejl under lagring af profildata",
	"ErrorValidatingInsuranceMembership": "Der opstod en fejl under tilmelding. Prøv venligst igen eller kontakt os, hvis problemet fortsætter.",
	"InvalidPhoneNumber": "Ugyldigt telefonnummer",
	"PhoneCallViewModel_ViewIsClosing": "Kaldet afsluttes når denne dialog bliver lukket",
	"ErrorGettingPrices": "Der opstod en fejl under hentning priser",
	"ErrorUpdatePrice": "Der opstod en fejl under opdatering pris",
	"InvalidPayment": "Ugyldig betaling",
	"TimeSlotUnavailable": "Tidspunktet er desværre allerede booket, vælg et andet tidspunkt.",
	"Child": "barn",
	"ErrorCompanyConsultations": "Der opstod en fejl under hentning af virksomhedens konsultationer",
	"Partner": "partner",
	"ErrorPatients": "Der opstod en fejl under hentningen af tilmeldte patienter",
	"NoDoctorsAvailableForQueueScheduleMessage": "Find en ledig tid herunder og bestil din videokonsultation. Vi sender dig en e-mail med yderligere information inden opkaldet.",
	"ValidateCprInvalidCpr": "Please enter a valid CPR number. The CPR number must be exactly 10-digits long.",
	"WaitingTimeText_Other": "Der er i øjeblikket ca. {minutes} minutters ventetid.",
	"WaitingTimeText_Singular": "Der er i øjeblikket ca. {minutes} minuts ventetid.",
	"Friday": "Fredag",
	"Monday": "Mandag",
	"Saturday": "Lørdag",
	"Sunday": "Søndag",
	"Thursday": "Torsdag",
	"Tuesday": "Tirsdag",
	"Wednesday": "Onsdag",
	"PayButton": "Gå til betaling",
	"SendButton": "Send",
	"Today": "I dag",
	"ErrorDeleteAccount": "Der opstod en fejl under sletning af konto",
	"ErrorInvitePartner": "Kunne ikke invitere familiemedlemmet. ",
	"ErrorCallQualityFeedback": "Kunne ikke gemme din feedback",
	"PleaseEnterFullName": "Indtast venligst det fulde navn",
	"CouldNotUpdateChild": "Kunne ikke opdatere barn ",
	"CouldNotCreateChild": "Kunne ikke oprette barn: ",
	"UploadFailedTooLarge": "Filen \"{0}\" er for stor, og kan derfor ikke uploades. Tjek venligst at din fil ikke er større end 30 MB.",
	"Profile_AddChild": "Tilføj barn",
	"Profile_EditChild": "Rediger barn",
	"FillOutMessage": "Udfyld venligst en besked.",
	"Darn": "Øv!",
	"SorryForYourConcerns": "Vi er kede af det ikke gik som forventet. Det vil være en stor hjælp for os, hvis du vil forklare hvad der var galt.",
	"ThankYou": "Tak!",
	"ImproveOurselves": "Vi vil altid gerne forbedre os. Fortæl os gerne hvordan.",
	"Great": "Dejligt!",
	"BigHelp": "Det vil være en stor hjælp, hvis du vil sætte et par ord på din oplevelse.",
	"dateFnsShortDateFormat": "d. MMM yyyy",
	"dateFnsTimeFormat": "HH.mm",
	"dateFnsTodayRelativeFormat": "'I dag kl.' HH:mm",
	"dateFnsTomorrowRelativeFormat": "'I morgen kl.' HH:mm",
	"ProfileConfirmEditKid": "Gem",
	"ClockValidatorError": "Uret på dit apparat går forkert. Indstil det venligst for at være sikker på, at Hejdoktor fungerer.",
	"DynamicCodeSubject": "Dynamic Code recept",
	"dateFnsLongDateFormat": "d. MMMM yyyy",
	"dateFnsLongDateTimeFormat": "d. MMMM yyyy 'kl.' HH.mm",
	"dateFnsShortDateTimeFormat": "d. MMM yyyy HH.mm",
	"NameRequired": "Indtast venligst dit fulde navn",
	"AddMore": "Tilføj flere",
	"MaxFilesExceeded": "Du kan ikke tilføje flere filer.",
	"InvalidFileType": "Ugyldig filtype.",
	"TimeZoneWarning": "Tidspunkterne vises i din lokale tidszone: {timezone}",
	"Back": "Tilbage",
	"Next": "Næste",
	"PFAErrorNoAnswer": "Du skal vælge en svarmulighed, før vi kan gå videre.",
	"SelectPlaceholder": "Vælg",
	"QuestionXOfY": "Spørgsmål {0} af {1}",
	"ErrorGettingCaregiverTips": "Der opstod en fejl under hentning af tips",
	"ErrorNewCaregiverTip": "Der opstod en fejl under oprettelse af nyt tip",
	"Understood": "Forstået"
}